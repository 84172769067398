import React, { useState, useEffect, createContext } from "react"
import { useCallback } from "react"
import { client } from "../util/data"

const defaultValues = {
  client,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: (variantId, quantity) => {},
  addBookingToCart: (id, quantity, properties) => {},
  removeLineItem: lineItemID => {},
  updateLineItem: (lineItemID, quantity) => {},
  cart: [],
  isOpen: false,
  loading: false,
  initialized: false,
  quantity: 0,
  didJustAddToCart: false,
  checkout: {
    lineItems: [],
    id: null,
  },
}

export const StoreContext = createContext(defaultValues)

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)
  const [initialized, setInitialized] = useState()
  const [quantity, setQuantity] = useState(0)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)
  const [isBrowser, setIsBrowser] = useState()
  const localStorageKey = `shopify_checkout_id`

  const setCheckoutItem = useCallback(
    checkout => {
      if (isBrowser) localStorage.setItem(localStorageKey, checkout.id)
      setCheckout(checkout)
    },
    [isBrowser, localStorageKey]
  )

  const regenerateCheckout = useCallback(async () => {
    const newCheckout = await client.checkout.create()
    setCheckoutItem(newCheckout)
    setInitialized(true)
    return newCheckout
  }, [setCheckoutItem])

  const getValidCheckout = useCallback(
    async force => {
      if (!isBrowser && !force) return
      const existingCheckoutID = localStorage.getItem(localStorageKey)
      if (!existingCheckoutID) return await regenerateCheckout()
      try {
        const existingCheckout = await client.checkout.fetch(existingCheckoutID)
        if (existingCheckout.completedAt) return await regenerateCheckout()
        setCheckoutItem(existingCheckout)
        updateQuantity(
          existingCheckout.lineItems.length
            ? existingCheckout.lineItems
            : existingCheckout.lineItems.edges
        )
        setInitialized(true)
        return existingCheckout
      } catch (e) {
        localStorage.removeItem(localStorageKey)
        return await regenerateCheckout()
      }
    },
    [isBrowser, localStorageKey, regenerateCheckout, setCheckoutItem]
  )

  const updateQuantity = lineItems =>
    setQuantity(
      lineItems?.reduce((total, item) => total + (item.node?.quantity || item.quantity || 0), 0) ||
        0
    )

  useEffect(() => {
    setIsBrowser(true)
    getValidCheckout(true)
  }, [getValidCheckout])

  const removeLineItem = async lineItemID => {
    const checkout = await getValidCheckout()
    setLoading(true)
    const response = await client.checkout.removeLineItems(checkout.id, [lineItemID])
    updateQuantity(response.lineItems)
    setCheckout(response)
    setLoading(false)
  }

  const addVariantToCart = async (variantId, quantity) => {
    setLoading(true)

    const checkout = await getValidCheckout()

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]
    try {
      const response = await client.checkout.addLineItems(checkout.id, lineItemsToUpdate)
      updateQuantity(response.lineItems)
      setCheckout(response)
      setLoading(false)
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 3000)
    } catch (error) {
      throw error
    }
  }

  const updateLineItem = async (lineItemID, quantity) => {
    setLoading(true)
    const checkout = await getValidCheckout()

    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity) }]

    const response = await client.checkout.updateLineItems(checkout.id, lineItemsToUpdate)
    updateQuantity(response.lineItems)
    setCheckout(response)
    setLoading(false)
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        removeLineItem,
        updateLineItem,
        checkout,
        quantity,
        loading,
        initialized,
        didJustAddToCart,
        addVariantToCart,
        client,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
