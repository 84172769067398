exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carrito-js": () => import("./../../../src/pages/carrito.js" /* webpackChunkName: "component---src-pages-carrito-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-productos-index-js": () => import("./../../../src/pages/productos/index.js" /* webpackChunkName: "component---src-pages-productos-index-js" */),
  "component---src-pages-productos-shopify-product-handle-js": () => import("./../../../src/pages/productos/{ShopifyProduct.handle}.js" /* webpackChunkName: "component---src-pages-productos-shopify-product-handle-js" */),
  "component---src-pages-reserva-js": () => import("./../../../src/pages/reserva.js" /* webpackChunkName: "component---src-pages-reserva-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */)
}

