import fetch from "isomorphic-fetch"
import Client from "shopify-buy/index.unoptimized.umd"
import data from "../data/fields"
import { toAMPM, toFormattedDate } from "./format"

export const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  },
  (url, params) => {
    const fixedParams = {
      ...params,
      headers: {
        ...params.headers,
        "X-Shopify-Storefront-Access-Token": process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
      },
    }
    return fetch(url, fixedParams)
  }
)

// Get stock for all variants of a single product. Could not optimize
export const getProductStock = async productId => {
  const query = await client.graphQLClient.query(root => {
    root.addConnection("products", { args: { first: 250 } }, product => {
      product.addConnection("variants", { args: { first: 250 } }, variant => {
        variant.add("id")
        variant.add("availableForSale")
        variant.add("quantityAvailable")
      })
    })
  })
  const response = await client.graphQLClient.send(query)
  for (let product of response.data.products?.edges) {
    if (product.node.id === productId)
      return (
        product.node.variants.edges?.map(({ node: variant }) => ({
          id: variant.id,
          stock: variant.availableForSale
            ? variant.quantityAvailable > 0
              ? variant.quantityAvailable
              : 0
            : 0,
        })) || []
      )
  }
  return []
}

// Get stock for a single variant of a product. Could not optimize
export const getVariantStock = async variant => {
  const productId = variant.product.id || variant.product.shopifyId
  const variantId = variant.id || variant.shopifyId
  const productStock = await getProductStock(productId)
  for (let subVariant of productStock) {
    if (subVariant.id === variantId) return subVariant.stock
  }
  return 0
}

export const bookings = async (productId, variantId) => {
  const formattedPID = productId.toString().replace("gid://shopify/Product/", "")
  const formattedVID = variantId?.toString()?.replace("gid://shopify/ProductVariant/", "")
  const response = await fetch(
    `https://servicify-appointments.herokuapp.com/api/products/${formattedPID}/events?variantId=${formattedVID}`
  )
  const { employees, id, duration, variants, numAttendees } = await response.json()

  // Single response for valid VID
  if (formattedVID) return {}

  // Multi-response for no VID
  const hours = employees[0].regularHours
  const attention = []

  Object.keys(hours).forEach(key => {
    attention[key] = {
      start: parseInt(hours[key].toString().split("-")[0]) || 0,
      end: parseInt(hours[key].toString().split("-")[1]) || 0,
    }
  })

  return {
    eventId: id,
    variantId: "gid://shopify/ProductVariant/" + variants[0],
    duration: duration,
    capacity: numAttendees,
    attention: attention,
  }
}

export const dayToNumber = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}

export const numberToDia = [
  "domingo",
  "lunes",
  "martes",
  "miércoles",
  "jueves",
  "viernes",
  "sábado",
]

export const parsePeriod = period => {
  const getSegment = segment => {
    const [hh, mm] = (segment || "").split(":").map(x => parseInt(x))
    return { hh: hh || 0, mm: mm || 0 }
  }
  const result = {}
  const [start, end] = period.split("-")
  result.start = getSegment(start)
  result.end = getSegment(end)
  return result
}

export const checkOverlap = (a, b) => {
  // Check hour not overlapping
  if (a.start.hh > b.end.hh || a.end.hh < b.start.hh) return false

  // Check minutes not overlapping
  // Case: a just after b
  if (a.start.hh === b.end.hh && a.start.mm >= b.end.mm) return false
  // Case: b just after a
  if (b.start.hh === a.end.hh && b.start.mm >= a.end.mm) return false

  // There's an overlap
  return true
}

const leadTimeUnits = {
  minutes: 1,
  hours: 60,
  days: 1440,
}

export const leadTimeToMinutes = ({ duration, unit }) => duration * leadTimeUnits[unit]

export const checkIfPastHour = (before, after) =>
  before.hh < after.hh || (before.hh === after.hh && before.mm <= after.mm)

export const generateReservationObject = ({ eventId, timezone, date, time, operator }) => {
  return {
    "Event #": eventId,
    "Booking Timezone": timezone,
    "Start Date": toFormattedDate(date),
    "Start Time": toAMPM(time.start),
    "End Time": toAMPM(time.end),
    "Booked With": operator,
  }
}

export const getProductUrl = handle => data.prefix.productDetail + handle
