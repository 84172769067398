export const formatPrice = price => {
  if (price % 1) return Number(price)
  return parseInt(price)
}

export const zeroPad = number => number.toString().padStart(2, "0")

export const toAMPM = ({ hh, mm }) => {
  const ampm = hh > 11 ? "pm" : "am"
  return `${zeroPad(hh > 12 ? hh - 12 : hh)}:${zeroPad(mm)} ${ampm}`
}

const ordinals = {
  1: "st",
  2: "nd",
  3: "rd",
  21: "st",
  22: "nd",
  23: "rd",
  31: "st",
}

export const toOrdinal = number => {
  if (ordinals[number]) return number + ordinals[number]
  return number + "th"
}

export const toFormattedDate = date => {
  const [month, weekday] = date
    .toLocaleDateString("en-us", { weekday: "long", month: "long" })
    .split(" ")
  return `${weekday}, ${month} ${toOrdinal(date.getDate())} ${date.getFullYear()}`
}
