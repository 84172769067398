const data = {
  url: {
    instagram: "//instagram.com/jockeyonice_arena",
    facebook: "//facebook.com",
    whatsapp: msg => "//api.whatsapp.com/send?phone=51935039485&text=" + encodeURIComponent(msg),
    iceGravityWhatsapp: msg =>
      "//api.whatsapp.com/send?phone=51989436428&text=" + encodeURIComponent(msg),
    linkedin: "//www.linkedin.com/company/jockey-on-ice-arena/",
    tiktok: "//tiktok.com/@jockeyonice_arena",
    waze: "//www.waze.com/ul/h6mc70q3mn",
    maps: "https://maps.app.goo.gl/UMfBYo3KpexDeeRJA?g_st=iw",
    reclamaciones: "//forms.gle/XGem4bH5UkjmKh6c6",
    nosotros: "/nosotros",
    servicios: "/servicios",
    index: "/",
    reserva: "/reserva",
    tienda: "/productos",
    carrito: "/carrito",
  },
  prefix: {
    productDetail: "/productos/",
  },
  strings: {
    seo: {
      default: {
        title: "Jockey On Ice",
        description: "Descubre la magia del hielo en el Jockey Club",
      },
    },
    mission:
      "Demostrar que se puede hacer empresa y apoyar a deportes poco desarrollados en el país y la región, que poseen un gran potencial y atractivo tanto para niños, adolescentes y adultos.",
    vision:
      "Elevar a los deportes de hielo a los más altos estándares deportivos, competitivos y recreativos en el país y la región con la creación del primer Centro de Alto Rendimiento Deportivo de Deportes de Hielo en la Región de Sudamérica.",
  },
  config: {
    eventEmployeeId: "17839",
  },
}

export default data
